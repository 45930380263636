
import { PropType, defineComponent } from 'vue';
import MetricComponent from "./MetricComponent.vue";
import { Client } from '../classes/client';
import { getFormattedDate } from '@/App.vue';

class clientTabsData {
    public fromDate = "";
    public toDate = "";
    public csvCount = 0;
    public refreshCount = 0;
}

export default defineComponent({
    name: 'ClientTabs',
    created() {

        //Default to past week
        const today = new Date();
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 6);

        this.fromDate = getFormattedDate(lastWeek);
        this.toDate = getFormattedDate(today);

    },
    components: {
        MetricComponent,
    },
    data() {
        return new clientTabsData();
    },
    props: {
        client: Object as PropType<Client>,
        showHeader: Boolean,
    },
    methods: {
        PushLoading() {
            this.$emit("pushloading");
        },
        PopLoading() {
            this.$emit("poploading");
        }
    }
});
