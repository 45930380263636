
import { AjaxGet, Alert, APIPath_Event, numberToShortDate } from '@/App.vue';
import { Metric } from '../classes/metric';
import { defineComponent, PropType } from 'vue';
import { GeoAREvent } from '../../../Firebase/functions/src/classes/GeoAREvent';

import { LineChart, PieChart } from 'vue-chart-3';
import { getFormattedDate } from '@/App.vue';

import { Parser } from '@json2csv/plainjs';

import download from 'in-browser-download';

class ChartDataSet {

    label = "";
    data: number[] = [];
    borderColor = "";
    backgroundColor: string | string[] = "";
    borderWidth = 5;

}

class ChartData {

    id = "";
    label = "";
    labels: string[] = [];
    datasets: ChartDataSet[] = [];

}

class MetricComponentData {

    public downloadComplete = false;
    public items: MetricComponentItems[] = [];
    public totalItems: MetricComponentTotalItems[] = [];

    public lineData = new ChartData();

    public pieCharts: ChartData[] = [];

    //Stuff relating to the line chart
    public lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
    }

    public hasLabels = false;
}

class MetricComponentItems {
    public date = "";
    public label = "";
    public count = 0;
}

class MetricComponentTotalItems {
    public label = "";
    public sum = 0;
}

const ChartColors = ["#36a2eb", "#ff6384", "#4bc0c0", "#ff9f40", "#9966ff", "#ffcd56", "#c9cbcf"];

export default defineComponent({

    name: 'MetricComponent',

    components: {
        LineChart,
        PieChart
    },

    props: {
        metric: {
            type: Object as PropType<Metric>,
        },
        from: String,
        to: String,
        refreshCount: Number,
        csvCount: Number,
        tabName: String,
    },
    data() {
        return new MetricComponentData();
    },

    watch: {
        refreshCount() {
            this.refresh();
        },
        csvCount() {
            this.csv();
        }
    },

    methods: {

        refreshPromise(): Promise<GeoAREvent[]> {

            return new Promise<GeoAREvent[]>((resolve, reject) => {

                this.downloadComplete = false;

                if (!this.metric || !this.from || !this.to) {
                    return;
                }

                this.$emit("startLoading");

                const minDate: number = parseInt(this.from.replaceAll("-", ""), 10);
                const maxDate: number = parseInt(this.to.replaceAll("-", ""), 10);

                AjaxGet<GeoAREvent[]>(APIPath_Event +
                    this.metric.ClientId + "/" +
                    this.metric.AppId + "/" +
                    this.metric.Category + "/" +
                    this.metric.Action + "/" +
                    minDate + "/" +
                    maxDate + "/", true).then((result) => {

                        this.lineData.datasets.length = 0;
                        this.lineData.labels.length = 0;
                        this.items.length = 0;
                        this.totalItems.length = 0;

                        //Count all of the items etc, combine into a single stream
                        const dict: { [date: string]: { [label: string]: MetricComponentItems } } = {};

                        //Count all of the dates etc, combine into a single stream
                        const dateDict: { [date: number]: { [label: string]: number } } = {};

                        //Dictionary of all labels, with the count for the total
                        const labelDict: { [id: string]: number } = {};

                        //Dictionary of all labels and operating systems of pie charts
                        const labelOSDict: { [label: string]: { [os: string]: number } } = {}

                        this.hasLabels = false;

                        for (const record of result) {

                            if (record.Label1 || record.Label2 || record.Label3) {
                                this.hasLabels = true;
                            }

                            let label = record.Label1 ?? "";
                            if (record.Label2) {
                                label += " - " + record.Label2;
                            }
                            if (record.Label3) {
                                label += " - " + record.Label3;
                            }
                            label = label.trim();

                            if (!labelDict[label]) {
                                labelDict[label] = 1;
                            }
                            else {
                                labelDict[label] += 1;
                            }

                            let os = FixOSLabel(record.OS);

                            if (!labelOSDict[label]) {
                                labelOSDict[label] = {};
                            }

                            if (!labelOSDict[label][os]) {
                                labelOSDict[label][os] = 1;
                            }
                            else {
                                labelOSDict[label][os] += 1;
                            }

                            if (!dict[record.Date]) {
                                dict[record.Date] = {};
                            }

                            if (dict[record.Date][label]) {
                                //This is an existing row
                                dict[record.Date][label].count += 1;
                            }
                            else {
                                //This is a new row
                                dict[record.Date][label] = {
                                    date: "" + record.Date,
                                    label: label,
                                    count: 1
                                }
                            }

                            if (!dateDict[record.Date]) {
                                dateDict[record.Date] = {};
                            }

                            if (dateDict[record.Date][label]) {
                                //This is an existing row
                                dateDict[record.Date][label] += 1;
                            }
                            else {
                                dateDict[record.Date][label] = 1;
                            }
                        }

                        let startDate = new Date(minDate / 10000, ((minDate / 100) % 100) - 1, minDate % 100);
                        let endDate = new Date(maxDate / 10000, ((maxDate / 100) % 100) - 1, maxDate % 100);

                        while (startDate <= endDate) {

                            const i = parseInt(getFormattedDate(startDate).replaceAll("-", ""));

                            if (!dateDict[i]) {
                                dateDict[i] = {};
                            }
                            this.lineData.labels.push(numberToShortDate(i));

                            startDate.setDate(startDate.getDate() + 1);
                        }


                        //Combine it all
                        for (const labelValue of Object.values(dict)) {
                            for (const item of Object.values(labelValue)) {
                                this.items.push(item);
                            }
                        }

                        let borderWidth = 2;

                        for (const label of Object.keys(labelDict)) {

                            this.totalItems.push({
                                label,
                                sum: labelDict[label]
                            });

                            let dataSet: ChartDataSet = {
                                label,
                                data: [],
                                borderColor: ChartColors[this.lineData.datasets.length % ChartColors.length],
                                backgroundColor: ChartColors[this.lineData.datasets.length % ChartColors.length],
                                borderWidth
                            };

                            //                        borderWidth += 5;

                            this.lineData.datasets.push(dataSet);

                            let startDate = new Date(minDate / 10000, ((minDate / 100) % 100) - 1, minDate % 100);
                            let endDate = new Date(maxDate / 10000, ((maxDate / 100) % 100) - 1, maxDate % 100);

                            while (startDate <= endDate) {

                                const i = parseInt(getFormattedDate(startDate).replaceAll("-", ""));

                                if (dateDict[i][label]) {
                                    dataSet.data.push(dateDict[i][label]);
                                }
                                else {
                                    dataSet.data.push(0);
                                }

                                startDate.setDate(startDate.getDate() + 1);
                            }

                        }

                        //Generate our  OS piecharts
                        this.pieCharts.length = 0;

                        //ONLY show if we actually have more than one OS represented
                        if (Object.keys(labelOSDict).length > 1) {
                            for (const label of Object.keys(labelOSDict)) {

                                if (Object.keys(labelOSDict[label]).length < 2) {
                                    continue;
                                }

                                let dataSet = new ChartDataSet();
                                dataSet.backgroundColor = [];

                                let chartData: ChartData = {
                                    id: "pie" + label,
                                    label,
                                    labels: [],
                                    datasets: [dataSet],
                                }

                                for (const os of Object.keys(labelOSDict[label])) {
                                    chartData.labels.push(os);
                                    dataSet.data.push(labelOSDict[label][os]);
                                    dataSet.backgroundColor.push(ChartColors[chartData.datasets[0].backgroundColor.length % ChartColors.length])
                                }

                                this.pieCharts.push(chartData);
                            }
                        }

                        this.downloadComplete = true;
                        resolve(result);

                    }).catch((err) => {
                        Alert(err);
                        reject(err);
                    }).finally(() => {
                        this.$emit("stopLoading");
                    })

            })

        },

        refresh() {

            this.refreshPromise();
        },

        csv() {

            this.refreshPromise().then((data) => {
                
                const opts = {};            
                const parser = new Parser(opts);
                const csv = parser.parse(data);
                download(csv, this.tabName + '.csv');

            })

        }

    },

    created() {
        this.refresh();
    }

});

function FixOSLabel(value: string): string {
    if (!value) {
        return "";
    }
    return value.split("/")[0].split("(")[0].trim();
}

