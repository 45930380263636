import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex justify-content-center h-100 flex-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ClientTabs = _resolveComponent("ClientTabs")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_LoginFormView = _resolveComponent("LoginFormView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      active: _ctx.LoadingCounter > 0
    }, null, 8, ["active"]),
    (_ctx.IsAuthenticated)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.Clients.length == 1)
            ? (_openBlock(), _createBlock(_component_ClientTabs, {
                key: 0,
                showHeader: true,
                client: _ctx.Clients[0],
                onPushloading: _ctx.PushLoading,
                onPoploading: _ctx.PopLoading
              }, null, 8, ["client", "onPushloading", "onPoploading"]))
            : (_openBlock(), _createBlock(_component_b_tabs, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Clients, (client, clientId) => {
                    return (_openBlock(), _createBlock(_component_b_tab, {
                      lazy: "",
                      title: client.displayName,
                      key: 'client' + clientId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ClientTabs, {
                          client: client,
                          showHeader: false,
                          onPushloading: _ctx.PushLoading,
                          onPoploading: _ctx.PopLoading
                        }, null, 8, ["client", "onPushloading", "onPoploading"])
                      ], undefined, true),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ], undefined, true),
                _: 1
              }))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoginFormView, {
            fbapp: _ctx.firebaseApp,
            onStartlogin: _cache[0] || (_cache[0] = ($event: any) => (_ctx.PushLoading())),
            onStoplogin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.PopLoading())),
            onUser: _ctx.LoggedIn
          }, null, 8, ["fbapp", "onUser"])
        ]))
  ]))
}