
import Vue, { defineComponent } from 'vue';
import LoginFormView from './views/LoginFormView.vue';
import { initializeApp } from 'firebase/app';
import axios from 'axios';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import { UserReturn } from "../../Firebase/functions/src/classes/User";

import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { getIdToken, UserCredential } from '@firebase/auth';
import { Metric } from './classes/metric';


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import {
  Chart,
  registerables
} from 'chart.js';
import { Client } from './classes/client';
import ClientTabs from "./components/ClientTabs.vue";

Chart.register(...registerables);

// eslint-disable-next-line
let appInstance: any = null;

const APIPath = "https://asia-southeast1-geoar-analytics.cloudfunctions.net/API/";
const APIPath_User = APIPath + "User/";
export const APIPath_Event = APIPath + "Event/";

const firebaseConfig = {
  apiKey: "AIzaSyChsyjp7bJ5fTPf8hY_OzT2CAPJ_hZVlPk",
  authDomain: "geoar-analytics.firebaseapp.com",
  databaseURL: "https://geoar-analytics-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "geoar-analytics",
  storageBucket: "geoar-analytics.appspot.com",
  messagingSenderId: "219934102526",
  appId: "1:219934102526:web:6ece571d2935af0d8026af",
  measurementId: "G-WZ33DS0Q40"
};

class Data {
  public firebaseApp = initializeApp(firebaseConfig);
  public LoadingCounter = 0;
  public UserCredential: UserCredential | null = null;
  public UserReturn: UserReturn | null = null;
  public IsAuthenticated = false;
  public Clients: Client[] = [];
}

export const data = new Data();

export function Alert(message: string) {
  //  appInstance.Alert(message);
  alert(message);
}

export function Prompt(message: string): Promise<PromptResult> {
  //  return appInstance.Prompt(message);
  return new Promise<PromptResult>((resolve) => {
    const result = prompt(message);

    if (result) {
      resolve({
        clickedOk: true,
        text: result
      });
    }
    else {
      resolve({ clickedOk: false, text: "" });
    }
  })
}

interface PromptResult {
  clickedOk: boolean;
  text: string;
}

let loadingCounter = 0;



const app = defineComponent({
  name: "App",
  data() {
    return data;
  },

  components: {
    LoginFormView,
    Loading,
    ClientTabs,
  },

  methods: {

    PushLoading() {
      loadingCounter++;
      this.LoadingCounter = loadingCounter;
    },

    PopLoading() {
      loadingCounter--;
      this.LoadingCounter = loadingCounter;
    },

    LoggedIn(credential: UserCredential) {

      this.PushLoading();
      this.UserCredential = credential;
      AjaxGet<UserReturn>(APIPath_User, true).then((result) => {

        this.UserReturn = result;
        this.IsAuthenticated = true;
        this.Clients = [];

        //Add our analytics
        if (this.UserReturn.clientData) {
          for (const client of this.UserReturn.clientData) {

            if (!client.analytics || !client.id) {
              continue;
            }

            const clientData = new Client();
            const analytics: Metric[] = [];

            for (const AppId of Object.keys(client.analytics)) {
              for (const Category of Object.keys(client.analytics[AppId])) {
                for (const Action of Object.keys(client.analytics[AppId][Category])) {
                  analytics.push({
                    UniqueId: Category + "_" + Action,
                    Category,
                    Action,
                    AppId,
                    Description: client.analytics[AppId][Category][Action].Description,
                    ClientId: client.id,
                  })
                }
              }
            }

            clientData.displayName = client.clientDisplayName ?? client.clientName ?? "";
            clientData.Analytics = analytics.sort((a, b) => a.Description.localeCompare(b.Description));
            this.Clients.push(clientData);

          }
        }

      }).catch(Alert).finally(this.PopLoading);
    }
  }
});



export default app;

const Authorization = "Authorization";

export function AjaxGet<T>(url: string, authenticate: boolean): Promise<T> {

  return new Promise<T>((resolve, reject) => {

    Promise.resolve().then(() => {

      if (authenticate) {
        if (!data.UserCredential) {
          reject(new Error("User credential error"));
          return;
        }
        return getIdToken(data.UserCredential.user);
      }
      else {
        return null;
      }

    }).then((token) => {

      const headers: { [id: string]: string } = {};
      if (authenticate) {
        headers[Authorization] = "Bearer " + token;
      }

      axios
        .get<T>(url, {
          headers,
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(new Error(err.response.data));
        });

    })

  });
}

/**
 * Get Formatted date only
 * @return {string} Formatted date
 */
export function getFormattedDate(date: Date): string {

  /**
   * Format integers to have at least two digits.
   * @param {number} n input number
   * @return {string} original number or string with at least two digits
   */
  function f(n: number): string {
    return n < 10 ? "0" + n : "" + n;
  }

  return date.getFullYear() + "-" +
    f(date.getMonth() + 1) + "-" +
    f(date.getDate());
}

export function numberToShortDate(value: number): string {

  const stringValue = value + "";
  return stringValue.substring(6, 8) + "/" + stringValue.substring(4, 6);

}

