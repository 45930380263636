
import { defineComponent, PropType } from "vue";
import FirebaseApp from "firebase/app";
import { Alert, Prompt } from "../App.vue";
import { getAuth, sendPasswordResetEmail, UserCredential, signInWithEmailAndPassword } from "firebase/auth";

const startlogin = "startlogin";
const stoplogin = "stoplogin";

export default defineComponent({
  name: "LoginForm",
  props: {
    fbapp: {
      type: Object as PropType<FirebaseApp.FirebaseApp>,
    },
  },
  data() {
    return {
      Email: "",
      Password: "",
    };
  },
  methods: {
    ResetPassword() {
      Prompt("Enter your email address").then((promptResult) => {
        if (promptResult.clickedOk && promptResult.text) {

          const auth = getAuth(this.fbapp);

          return sendPasswordResetEmail(auth, promptResult.text).then(() => {
            Alert("Password reset email sent")
          })


        } else {
          return null;
        }
      });
    },

    LoginKeyPress(e: KeyboardEvent) {
      if (e.keyCode === 13) {
        this.Login();
      }
    },
    Login() {

      let credential: UserCredential;
      this.$emit(startlogin);

      const auth = getAuth(this.fbapp);

      signInWithEmailAndPassword(auth, this.Email, this.Password)
        .then((result) => {
          credential = result;
          if (!credential || !credential.user) {
            throw new Error("Could not log in");
          }
          this.$emit("user", credential);
        })
        .catch((error) => {
          Alert(error);
        })
        .finally(() => {
          this.$emit(stoplogin);
        });
    },
  },
});
